import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "ITC Goa",
  address: "Miramar, Goa",
  client: "ITC",
  area: "2.0 Acres",
  project: "ITC, Miramar, Goa",
  category: "Landscape | Architecture",
  status: "Completed",
  backlink: "/projects/landscape/",
};

export default class Projectitcgoa extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Hospitality Projects | ITC Goa"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View the completed and unique contemporary landscape architecture project for a beautiful hotel property in Goa."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
