const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcgoa/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcgoa/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcgoa/3.png",
    text: "3",
  },
];

export default data;
